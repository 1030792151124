<template>
  <div class="content-layout">
    <v-dialog v-model="showModal" persistent width="600px">
      <v-card>
        <v-card-title>
          <span class="lfont ml-3">{{ $t("map.wifi") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="10" md="10">
                <v-text-field
                  label="Wifi IP"
                  v-model="wifi_ip"
                  class="mt-1"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-btn class="btn-save-change" @click="fetchAutoWifiIp()">
                  Auto
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  label="Description"
                  auto-grow
                  outlined
                  rows="3"
                  row-height="15"
                  v-model="description"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveChange">
            Save
          </v-btn>
          <v-btn color="dark" text @click="closeModal">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { emit } from "process";

export default {
  props: ["dialog", "Gps_id"],
  data() {
    return {
      wifi_ip: "",
      description: "",
      server_errors: {
        description: "",
        wifi_ip: "",
      },
      btnLoading: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    saveChange() {
      const items = {
        clock_in_zone_id: this.Gps_id,
        wifi_ip: this.wifi_ip,
        description: this.description,
      };
      this.$axios
        .post(`company/wifi-ip`, items)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$emit("close");
            this.$emit("success");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },

    fetchAutoWifiIp() {
      this.isLoading = true;
      this.$axios
        .get(`company/get-wifi-ip`)
        .then((res) => {
          console.log(res);
          this.wifi_ip = res.data.data;
          // console.log(this.itemGps, "loggggggg");
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    showModal: function() {
      return this.dialog;
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.content-layout {
  padding: 20px;
  font-family: "Noto Sans Lao" !important;
}
.btn {
  padding: 30px !important;
}
.btn-save {
  color: white !important;
  margin-right: 30px;
}
.user {
  color: blue;
}
</style>
